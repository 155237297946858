/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the edit quarantined stock modal component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, MenuItem, Theme, makeStyles, Grid, TextField } from '@material-ui/core';

import { Form, SubmitButton } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IUsePharmacyActions } from '../hooks/usePharmacy';
import * as Dtos from '../api/dtos';
import { IQuarantineRemoveQuarantinedStockForm } from '../store/modules/pharmacy';
import useModal from '../hooks/useModal';
import { QuarantinedStockAction } from '../api/dtos';
import { selectDistinctObjects } from '../utilities/selectDistinctObjects';


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    caption: {
        paddingBottom: theme.spacing(2)
    },
    batchGridItem: {
        paddingTop: theme.spacing(2)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IEditQuarantinedStockModalProps {
    pharmacyId: number;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    pharmacyActions: IUsePharmacyActions;
    quarantinedDepotBatches:  Dtos.DrugShipmentDepotBatch[]
}

interface IEditQuarantinedStockFormProps {
    value: IQuarantineRemoveQuarantinedStockForm;
    setValue: (value: IQuarantineRemoveQuarantinedStockForm) => void;
    setModalOpen: (open: boolean) => void;
    batches: Dtos.Batch[]
}


const EditQuarantinedStockForm: React.FunctionComponent<IEditQuarantinedStockFormProps> = ({
    value, 
    setValue,
    setModalOpen,
    batches
}) => {

    const classes = useStyles();

    const handleBatchStateChange = React.useCallback((id: number, eventValue: string) => {
        
        if (value.batchActions[id]) {
            const state: number = parseInt(eventValue);

            const valueCopy: IQuarantineRemoveQuarantinedStockForm = { ...value };

            valueCopy.batchActions[id] = state;

            setValue(valueCopy);
        }

    }, [value, setValue])

    const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setModalOpen(false);
    }, [setModalOpen]);

    return (
        <>
            <DialogContent>
                <div
                    className={classes.caption}
                >
                    Select the action to take on the quarantined stock.
                </div>
                <Grid container>
                    <Grid item xs={6}>
                        <strong>Batch Number</strong>
                    </Grid>
                    <Grid item xs={6}>
                        <strong>Action</strong>
                    </Grid>
                    {
                        batches && 
                        batches.map((batch, idx) => (
                            <React.Fragment
                                key={idx}
                            >   
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.batchGridItem}
                                >
                                    {batch.batchNo}
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.batchGridItem}
                                >
                                    <TextField
                                        fullWidth
                                        select
                                        defaultValue={Dtos.QuarantinedStockAction.Quarantined}
                                        onChange={(e) => handleBatchStateChange(batch.id ?? 0, e.target.value)}
                                    >
                                        <MenuItem key={idx + "-0"} value={QuarantinedStockAction.Quarantined}>Leave in Quarantine</MenuItem>
                                        <MenuItem key={idx + "-1"} value={QuarantinedStockAction.ReturnStockToSiteSupply}>Return Batch to Site Supply</MenuItem>
                                        <MenuItem key={idx + "-2"} value={QuarantinedStockAction.DestroyStock}>Destroy Batch</MenuItem>
                                    </TextField>
                                </Grid>
                            </React.Fragment>
                        ))
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const EditQuarantinedStockModal: React.FunctionComponent<IEditQuarantinedStockModalProps> = ({
    pharmacyId,
    modalOpen,
    setModalOpen,
    onCloseClick,
    onFormSave,
    pharmacyActions,
    quarantinedDepotBatches
}) => {

    const distinctBatches = React.useMemo(() => !quarantinedDepotBatches || quarantinedDepotBatches.length === 0 ?
        [] :
        selectDistinctObjects(quarantinedDepotBatches.map(dsdb => dsdb.depotBatch.batch),
            (reduceObject, returnObject) => reduceObject.id === returnObject.id),
        [quarantinedDepotBatches]);

    const initialValue = React.useMemo(() => {
        return Object.assign({}, {
            pharmacyId,
            batchActions: distinctBatches.reduce<Record<number, number>>((a, b) => {
                a[b?.id!] = QuarantinedStockAction.Quarantined;

                return a;

            }, {})
        }) as IQuarantineRemoveQuarantinedStockForm;
    }, [pharmacyId, distinctBatches]);

    const [value, setValue] = React.useState<IQuarantineRemoveQuarantinedStockForm>(initialValue);

    const {
        handleSubmit,
        onFormSubmitFailure,
        debouncedValidate
    } = useModal({
        entityName: 'batch',
        asyncSave: pharmacyActions.asyncActionQuarantinedStock,
        onFormSave: onFormSave,
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Edit Stock in Quarantine
                </DialogTitle>
                <Form
                    initialValues={value}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onSubmitFailed={onFormSubmitFailure}
                    onValidate={debouncedValidate}
                >
                    <EditQuarantinedStockForm
                        value={initialValue}
                        setValue={v => setValue(v)}
                        setModalOpen={setModalOpen}
                        batches={distinctBatches}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default EditQuarantinedStockModal;