/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drug management landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Link } from 'react-router-dom';

import { useParams, useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { Theme, makeStyles } from '@material-ui/core/styles';

import { DateTime } from 'luxon';

import { Button, Grid } from '@material-ui/core';

import { Column } from 'material-table';

import { RequestState, IRequestState } from '@ngt/request-utilities';

import {
    InstitutionSelector,
    usePatientsByCodes,
    CollapsibleTable,
    IInstitution,
    IPatient,
    useInstitutionsByCodes,
    ALL_INSTITUTIONS_CODE,
    useSnackbar
} from '@ngt/opms';

import { ResponseStatus } from '@servicestack/client';

import AlertTitle from '@material-ui/lab/AlertTitle';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { usePharmacies } from '../hooks/usePharmacies';
import PharmacyModal from '../modals/PharmacyModal';
import { useDrugs } from '../hooks/useDrugs';
import { useDepots } from '../hooks/useDepots';
import { usePharmacy } from '../hooks/usePharmacy';
import { useDrugShipments } from '../hooks/useDrugShipments';
import { useDrugShipment, IUseDrugShipmentActions } from '../hooks/useDrugShipment';
import DrugShipmentModal from '../modals/DrugShipmentModal';
import { useDepotBatches, IUseDepotBatchesActions } from '../hooks/useDepotBatches';
import { getShipmentStatusText } from '../utilities/getShipmentStatusText';
import DrugShipmentStatusModal from '../modals/DrugShipmentStatusModal';
import DrugBreadcrumbs from '../components/DrugBreadcrumbs';
import { usePharmacists } from '../hooks/usePharmacists';
import DrugShipmentDeleteModal from '../modals/DrugShipmentDeleteModal';
import { getDrugRemaining } from '../utilities/drugShipmentDepotBatchCalculators';
import DrugShipmentReceivedModal from '../modals/DrugShipmentReceivedModal';
import DrugShipmentTableButtons from '../components/DrugShipmentTableButtons';
import { useDrugShipmentVerify } from '../hooks/useDrugShipmentVerify';
import { usePatientsDrugs } from '../hooks/usePatientsDrugs';
import { getPharmacyStatusText } from '../utilities/getPharmacyStatusText';
import DrugManagementContext, { IDrugManagementContext } from '../context/DrugManagementContext';
import { useLoading } from '../hooks/useLoading';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugManagementPageParams {
    countryCode?: string;
    institutionCode?: string;
}

interface IDrugManagementPageProps {
    canAdministerDrugManagement: boolean;
    canViewDrugManagement: boolean;
}

interface IPharmacyWithDrugCount extends Dtos.Pharmacy {
    drugCount: Record<number, number>;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    buttonSet: {
        textAlign: 'right'
    },
    button: {
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const usePharmacyColumns = (pharmacies: IPharmacyWithDrugCount[] | null,
    drugs: Dtos.Drug[] | null) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<IPharmacyWithDrugCount>> = [
            {
                title: 'Pharmacy Name',
                field: 'pharmacyName'
            },
            {
                title: 'Status',
                field: 'pharmactStatus',
                render: (p) => getPharmacyStatusText(p.pharmacyStatus)
            },
        ];

        if (drugs) {
            for (const drug of drugs) {
                cols.push({
                    title: drug.drugName,
                    render: (p) => p.drugCount[drug.id!]
                });
            }
        }

        return cols;
    }, [pharmacies, drugs]);

    return columns as any;
};

const getShipmentStatusDate = (drugShipment: Dtos.DrugShipment): string => {
    const statusDate = drugShipment
        .drugShipmentStatusHistory[drugShipment.drugShipmentStatusHistory.length - 1]
        .statusDate;

    return statusDate;
};

const getPharmaciesWithDrugCount = (pharmacies: Dtos.Pharmacy[],
    drugShipments: Dtos.DrugShipment[] | null,
    drugShipmentLoadState: IRequestState<ResponseStatus>,
    drugs: Dtos.Drug[] | null): IPharmacyWithDrugCount[] => {
    const pharmaciesWithDrugCount: IPharmacyWithDrugCount[] = [];

    if (pharmacies &&
        drugShipmentLoadState &&
        drugShipmentLoadState.state !== RequestState.Success) {
        pharmacies.forEach(pharmacy => {
            pharmaciesWithDrugCount.push({ ...pharmacy, drugCount: {} });
        });
    }

    if (pharmacies &&
        drugs &&
        drugShipmentLoadState &&
        drugShipmentLoadState.state === RequestState.Success) {

        const drugsArray = drugs.map(d => d.id!);

        pharmacies.forEach(pharmacy => {
            const drugsAtPharmacy = drugsArray.reduce<Record<number, number>>(
                (reducedValue, currentItem) => {
                    reducedValue[currentItem] = 0;
                    return reducedValue;
                },
                {}
            );

            const drugSums = drugShipments ?
                drugShipments
                    .filter(ds => ds.pharmacyId === pharmacy.id &&
                        ds.shipmentStatus === Dtos.ShipmentStatus.Available)
                    .flatMap(drugShipment => drugShipment.drugShipmentDepotBatches.map(dsdb => { return { drugId: dsdb.depotBatch.batch.drugId, drugUnits: getDrugRemaining(dsdb) } }))
                    .reduce<Record<number, number>>((reducedValue, currentItem) => {
                        if (!currentItem.drugId) {
                            return reducedValue;
                        }

                        if (reducedValue[currentItem.drugId]) {
                            reducedValue[currentItem.drugId] += currentItem.drugUnits;
                        }
                        else {
                            reducedValue[currentItem.drugId] = currentItem.drugUnits;
                        }

                        return reducedValue;
                    },
                    {}) :
                {};

            Object.keys(drugSums).forEach(k => {
                const key = parseInt(k, 10);
                drugsAtPharmacy[key] = drugSums[key];
            });

            pharmaciesWithDrugCount.push({ ...pharmacy, drugCount: drugsAtPharmacy });
        });
    }

    return pharmaciesWithDrugCount;
}

const useDrugShipmentColumns = (drugShipments: Dtos.DrugShipment[] | null,
    pharmacies: Dtos.Pharmacy[] | null,
    drugShipmentActions: IUseDrugShipmentActions,
    depotBatchesActions: IUseDepotBatchesActions,
    setDrugShipmentStatusModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setDrugShipmentStatus: React.Dispatch<React.SetStateAction<Dtos.ShipmentStatus | null>>,
    setDeleteDrugShipmentId: React.Dispatch<React.SetStateAction<number | null>>,
    setDrugShipmentDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    canAdministerDrugManagement: boolean | null,
    consignmentNumberLabel: string) => {

    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.DrugShipment>> = [
            {
                title: 'Pharmacy Name',
                field: 'pharmacyName',
                render: (drugShipment) => pharmacies?.find(p => p.id === drugShipment.pharmacyId)?.pharmacyName
            },
            {
                title: 'Last Update',
                field: 'lastUpdate',
                render: drugShipment => DateTime.fromISO(getShipmentStatusDate(drugShipment)).toFormat('dd/MM/yyyy')
            },
            {
                title: consignmentNumberLabel,
                field: 'consignmentNo'
            },
            {
                title: 'Status',
                field: 'pharmacyStatus',
                render: (drugShipment) => getShipmentStatusText(drugShipment.shipmentStatus!!)
            },
            {
                title: 'Actions',
                field: 'actions',
                //disableSortBy: true,
                render: (drugShipment: Dtos.DrugShipment) => {
                    return (
                        <>
                            {
                                <DrugShipmentTableButtons
                                    drugShipment={drugShipment}
                                    shipmentStatus={drugShipment.shipmentStatus!!}
                                    drugShipmentActions={drugShipmentActions}
                                    depotBatchesActions={depotBatchesActions}
                                    setDrugShipmentStatus={setDrugShipmentStatus}
                                    setDeleteDrugShipmentId={setDeleteDrugShipmentId}
                                    setDrugShipmentDeleteModalOpen={setDrugShipmentDeleteModalOpen}
                                    canAdministerDrugManagement={canAdministerDrugManagement}
                                />
                            }
                        </>
                    );
                }
            } as any
        ];

        return cols;
    },
    [
        drugShipments,
        pharmacies,
        drugShipmentActions,
        depotBatchesActions,
        setDrugShipmentStatusModalOpen,
        setDrugShipmentStatus,
        setDeleteDrugShipmentId,
        setDrugShipmentDeleteModalOpen,
        canAdministerDrugManagement
    ]);

    return columns as any;
};

const usePatientColumns = (patients: IPatient[] | null,
    institutions: IInstitution[] | null,
    patientsDrugs: Dtos.PatientDrug[] | null,
    drugManagementContext: IDrugManagementContext) => {
    const columns = React.useMemo(() => {
        const cols: Array<Column<IPatient>> = [
            {
                title: 'Patient Number',
                field: 'studyNumber'
            },
            {
                title: 'Institution',
                field: 'institution',
                render: (patient) => institutions?.find(i => i.id === patient.institutionId)?.name
            }
        ];

        if (drugManagementContext.treatments?.length > 0) {
            cols.push({
                title: 'Treatment',
                field: 'treatment',
                render: (patient) => drugManagementContext.treatments[(patient.treatmentId ?? 0)]
            });
        }

        cols.push({
            title: 'Treatment Status',
            field: 'treatmentStatus',
            render: (patient) => {
                const patientDrug = patientsDrugs?.find(pd => pd.patientId === patient.id &&
                    pd.drugId === 1);

                if (patientDrug &&
                    patientDrug.patientTreatmentStatus === Dtos.PatientTreatmentStatus.OffTreatment) {
                    return 'Off Treatment';
                }

                return 'On Treatment';
            }
        });

        return cols;
    }, [patients, institutions, patientsDrugs]);

    return columns;
};


/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */


const DrugManagementPage: React.FunctionComponent<IDrugManagementPageProps> = ({
    canAdministerDrugManagement,
    canViewDrugManagement
}) => {
    const classes = useStyles();

    const history = useHistory();

    const params = useParams<IDrugManagementPageParams>();

    const drugManagementContext = React.useContext(DrugManagementContext);

    const [institutions, institutionsLoadState, ] = useInstitutionsByCodes(null, null, null, true);

    React.useEffect(() => {
        if (!canViewDrugManagement) {
            //i'm putting this here explicitly because we don't have any permissions on who can go to what route at the moment
            //on the off chance the BCT add a link directly to the drug management page, we want to send people who can't see
            //drug management to the 403 page
            history.replace(`/error/403`);
        }
        else if (institutions && institutions?.length === 1) {
            //just send the patient to their matching institution if they only have one
            history.replace(`${drugManagementContext.baseUrl}/${institutions[0].code}`);
        }
    }, [institutions, canViewDrugManagement]);

    const validCodes = React.useMemo(() => {
        return [
            drugManagementContext.randomisedStatus
        ];
    }, [drugManagementContext.randomisedStatus]);

    const [patients, patientsLoadState, patientsActions] = usePatientsByCodes(null, null, null, null, validCodes, true);

    const [,,,,, pharmacyActions] = usePharmacy();

    const [pharmacies, pharmaciesLoadState, pharmaciesActions] = usePharmacies();

    const [drugShipment, drugShipmentLoadState, drugShipmentSaveState,,, drugShipmentActions] = useDrugShipment();

    const [,, drugShipmentVerifyActions] = useDrugShipmentVerify();

    const [drugShipments, drugShipmentsLoadState, drugShipmentsActions] = useDrugShipments();

    const [depots, depotsLoadState, depotsActions] = useDepots();

    const [pharmacyForm, setPharmacyForm] = React.useState<Dtos.Pharmacy | null>(null);

    const [drugShipmentForm, setDrugShipmentForm] = React.useState<Dtos.DrugShipment | null>(null);

    const [newShipmentStatus, setDrugShipmentStatus] = React.useState<Dtos.ShipmentStatus | null>(null);

    const [deleteDrugShipmentId, setDeleteDrugShipmentId] = React.useState<number | null>(null);

    const [pharmacyModalOpen, setPharmacyModalOpen] = React.useState(false);

    const [drugShipmentModalOpen, setDrugShipmentModalOpen] = React.useState(false);

    const [drugShipmentStatusModalOpen, setDrugShipmentStatusModalOpen] = React.useState(false);

    const [drugShipmentDeleteModalOpen, setDrugShipmentDeleteModalOpen] = React.useState(false);

    const [drugShipmentReceivedModalOpen, setDrugShipmentReceivedModalOpen] = React.useState(false);

    const [depotBatches, depotBatchesLoadState, depotBatchesActions] = useDepotBatches();

    const [pharmacists, pharmacistsLoadState, pharmacistsActions] = usePharmacists();

    const [drugs, drugLoadState, drugActions] = useDrugs();

    const [patientsDrugs,, patientsDrugsActions] = usePatientsDrugs();

    //filter pharmacies
    //filter shipments

    const pharmaciesToUse = React.useMemo(() => {
        if (pharmacies) {
            if (params.institutionCode) {
                return pharmacies?.filter(p => p.institutionCode === params.institutionCode);
            }
            else {
                return pharmacies;
            }
        }

        return null;
    }, [pharmacies, params.institutionCode]);

    const drugShipmentsToUse = React.useMemo(() => {
        if (drugShipments) {
            if (params.institutionCode) {
                return [...drugShipments].filter(d => d.institutionCode === params.institutionCode)
                    .sort((a, b) => {
                        return getShipmentStatusDate(a) > getShipmentStatusDate(b) ?
                            -1 :
                            1;
                    });
            }
            else {
                return [...drugShipments].sort((a, b) => {
                    return getShipmentStatusDate(a) > getShipmentStatusDate(b) ?
                        -1 :
                        1;
                });
            }
        }

        return null;
    }, [drugShipments, params.institutionCode]);

    const convertedPharmacies = React.useMemo(() => {
        if (pharmaciesToUse) {
            return getPharmaciesWithDrugCount(pharmaciesToUse,
                drugShipmentsToUse,
                drugShipmentsLoadState,
                drugs);
        }

        return null;
    }, [pharmaciesToUse, drugShipmentsToUse, drugShipmentsLoadState]);

    const patientsToUse = React.useMemo(() => {
        if (patients && institutions) {
            if (params.institutionCode) {
                const inst = institutions.find(i => i.code === params.institutionCode);

                if (inst) {
                    return patients.filter(p => p.institutionId === inst.id);
                }
            }
            else {
                return patients.filter(p => institutions.find(i => i.id === p.institutionId)) as IPatient[];
            }
        }

        return null;
    }, [patients, institutions, params.institutionCode]);

    const pharmacyColumns = usePharmacyColumns(convertedPharmacies,
        drugs);

    const drugShipmentColumns = useDrugShipmentColumns(drugShipments,
        pharmaciesToUse,
        drugShipmentActions,
        depotBatchesActions,
        setDrugShipmentStatusModalOpen,
        setDrugShipmentStatus,
        setDeleteDrugShipmentId,
        setDrugShipmentDeleteModalOpen,
        canAdministerDrugManagement,
        drugManagementContext.consignmentNumberLabel);

    const patientColumns = usePatientColumns(patientsToUse as IPatient[],
        institutions,
        patientsDrugs,
        drugManagementContext);

    const pharmaciesLoading = useLoading(convertedPharmacies,
        pharmaciesLoadState);

    const drugShipmentsLoading = useLoading(drugShipments,
        drugShipmentsLoadState);

    const patientsLoading = useLoading(patients,
        patientsLoadState);

    React.useEffect(() => {
        drugActions.load();

        return () => {
            drugActions.clear();
        };
    }, []);

    React.useEffect(() => {
        depotsActions.load();

        return () => {
            depotsActions.clear();
        };
    }, []);

    React.useEffect(() => {
        pharmaciesActions.load();

        return () => {
            pharmaciesActions.clear();
        }
    }, []);

    React.useEffect(() => {
        drugShipmentsActions.load();

        return () => {
            drugShipmentsActions.clear();
        };
    }, []);

    React.useEffect(() => {
        if (params.institutionCode &&
            pharmacies &&
            pharmacies.some(p => p.institutionCode === params.institutionCode)) {
            depotBatchesActions.loadByInstCode(params.institutionCode);
        }

        return () => {
            depotBatchesActions.clear();
        };
    }, [params.institutionCode, pharmacies]);

    React.useEffect(() => {
        if (params.institutionCode) {
            pharmacistsActions.loadByInstCode(params.institutionCode);
        }

        return () => {
            pharmacistsActions.clear();
        };
    }, [params.institutionCode]);

    React.useEffect(() => {
        patientsActions.load();

        return () => {
            patientsActions.clear();
        }
    }, [patientsActions]);

    React.useEffect(() => {
        patientsDrugsActions.load();

        return () => {
            patientsDrugsActions.clear();
        };
    }, []);

    const onPharmacyRowClick = React.useCallback((event?: React.MouseEvent,
        rowData?: IPharmacyWithDrugCount) => {
        if (rowData &&
            rowData.id) {
            history.push(`${drugManagementContext.baseUrl}/pharmacy/${rowData.id}`);
        }
    }, []);

    const onDrugShipmentRowClick = React.useCallback((event?: React.MouseEvent,
        rowData?: Dtos.DrugShipment) => {
        if (rowData &&
            rowData.id &&
            canAdministerDrugManagement) {
            setDrugShipmentStatus(null);
            depotBatchesActions.loadByDrugShipmentId(rowData.id);
            drugShipmentActions.load(rowData.id);
        }
    }, [canAdministerDrugManagement, setDrugShipmentStatus, depotBatchesActions, drugShipmentActions]);

    const onPatientRowClick = React.useCallback((event?: React.MouseEvent,
        rowData?: IPatient) => {
        if (rowData &&
            rowData.id &&
            institutions) {
            history.push(`${drugManagementContext.baseUrl}/${institutions.find(i => i.id === rowData.institutionId)?.code}/${rowData.studyNumber}/drug-dispensation`);
        }
    }, [institutions]);

    const addPharmacyClick = React.useCallback(() => {
        if (drugs) {
            setPharmacyForm(new Dtos.Pharmacy({
                institutionCode: params.institutionCode,
                pharmacyDrugs: drugs.map(d => new Dtos.PharmacyDrug({
                    drugId: d.id,
                    enabled: true
                }))
            }));

            setPharmacyModalOpen(true);
        }
    }, [drugs, params.institutionCode, setPharmacyModalOpen]);

    const addDrugShipmentClick = React.useCallback(() => {
        setDrugShipmentForm(new Dtos.DrugShipment({
            institutionCode: params.institutionCode,
            shipmentStatus: Dtos.ShipmentStatus.New,
            drugShipmentStatusHistory: [
                new Dtos.DrugShipmentStatusHistory({
                    shipmentStatus: Dtos.ShipmentStatus.New,
                    statusDate: DateTime.local().toISODate()
                })
            ],
            drugShipmentDepotBatches: [
                new Dtos.DrugShipmentDepotBatch()
            ]
        }));

        setDrugShipmentModalOpen(true);
    }, [params.institutionCode, setDrugShipmentModalOpen]);

    const onPharmacyFormSave = React.useCallback(() => {
        setPharmacyModalOpen(false);
        pharmacyActions.clear();
        pharmaciesActions.load();
        drugShipmentsActions.load();
    }, []);

    const onDrugShipmentFormSave = React.useCallback(() => {
        setDrugShipmentModalOpen(false);
        setDrugShipmentStatus(null);
        drugShipmentActions.clear();
        drugShipmentsActions.load();
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    const onDrugShipmentFormSaveError = React.useCallback(() => {
        
    }, []);

    React.useEffect(() => {
        drugShipmentSaveState.responseStatus?.errorCode === 'ClientViewableException' && enqueueSnackbar(
            <>
                <AlertTitle>
                    {
                        `Drug Shipment Not Saved`
                    }
                </AlertTitle>
                {
                    drugShipmentSaveState.responseStatus?.errorCode === 'ClientViewableException' ?
                        drugShipmentSaveState.responseStatus?.message : `An error occurred while attempting to save the drug shipment.`
                }
            </>,
            { variant: 'critical' }
        );
    }, [drugShipmentSaveState.responseStatus?.errorCode]);


    const onDrugShipmentStatusFormSave = React.useCallback(() => {
        setDrugShipmentStatusModalOpen(false);
        setDrugShipmentStatus(null);
        drugShipmentActions.clear();
        drugShipmentsActions.load();
    }, []);

    const onDrugShipmentReceivedFormSave = React.useCallback(() => {
        setDrugShipmentReceivedModalOpen(false);
        setDrugShipmentStatus(null);
        drugShipmentActions.clear();
        drugShipmentsActions.load();
    }, []);

    const onDrugShipmentDelete = React.useCallback(() => {
        setDrugShipmentDeleteModalOpen(false);
        setDeleteDrugShipmentId(null);
        drugShipmentActions.clear();
        drugShipmentsActions.load();
    }, []);

    const onDrugShipmentDeleteClose = React.useCallback(() => {
        setDeleteDrugShipmentId(null);
    }, []);

    const onDrugShipmentReceivedExit = React.useCallback(() => {
        drugShipmentVerifyActions.clear();
    }, [drugShipmentVerifyActions]);

    React.useEffect(() => {
        if (drugShipmentLoadState.state === RequestState.Success) {
            setDrugShipmentForm(drugShipment);

            //if a new shipment status has been set, this means we wanted to open the shipment status modal
            //unless the new status is available, in which case we wanted to open the drug shipment received modal
            if (newShipmentStatus &&
                newShipmentStatus !== Dtos.ShipmentStatus.Available) {
                setDrugShipmentStatusModalOpen(true);
            }
            else if (newShipmentStatus &&
                newShipmentStatus === Dtos.ShipmentStatus.Available) {
                setDrugShipmentReceivedModalOpen(true);
            }
            else {
                setDrugShipmentModalOpen(true);
            }
        }
    }, [drugShipmentLoadState.state]);

    //this is here for general cleanup in case something is loaded into redux and never reset
    React.useEffect(() => {
        return () => {
            setDrugShipmentStatus(null);
            setDeleteDrugShipmentId(null);
            drugShipmentActions.clear();
            depotBatchesActions.clear();
            drugShipmentVerifyActions.clear();
        };
    }, []);

    const onSelectedInstChange = React.useCallback((code: string | null | undefined) => {
        let route: string = `${drugManagementContext.baseUrl}`;

        if (code &&
            code !== ALL_INSTITUTIONS_CODE) {
            route += `/${code}`;
        }

        history.replace(route);
    }, [history]);

    return (
        <>
            <DrugBreadcrumbs
                institutionName={institutions?.find(i => i.code === params.institutionCode)?.name}
                institutionCode={params.institutionCode}
                showInstitution={true}
            />
            <div
                className={classes.container}
            >
                <PharmacyModal
                    pharmacists={pharmacists}
                    pharmacistsLoadState={pharmacistsLoadState}
                    depots={depots}
                    depotsLoadState={depotsLoadState}
                    drugs={drugs}
                    drugLoadState={drugLoadState}
                    institutionLoadState={institutionsLoadState}
                    institutions={institutions}
                    pharmacy={pharmacyForm}
                    modalOpen={pharmacyModalOpen}
                    setModalOpen={setPharmacyModalOpen}
                    onFormSave={onPharmacyFormSave}
                    pharmacyActions={pharmacyActions}
                />
                <DrugShipmentModal
                    depotBatches={depotBatches}
                    depotBatchesLoadState={depotBatchesLoadState}
                    drugs={drugs}
                    drugLoadState={drugLoadState}
                    institutionLoadState={institutionsLoadState}
                    institutions={institutions}
                    drugShipment={drugShipmentForm}
                    modalOpen={drugShipmentModalOpen}
                    setModalOpen={setDrugShipmentModalOpen}
                    onFormSave={onDrugShipmentFormSave}
                    onFormSaveError={onDrugShipmentFormSaveError}
                    drugShipmentActions={drugShipmentActions}
                    canAdministerDrugManagement={canAdministerDrugManagement}
                />
                <DrugShipmentReceivedModal
                    depotBatches={depotBatches}
                    depotBatchesLoadState={depotBatchesLoadState}
                    drugs={drugs}
                    drugLoadState={drugLoadState}
                    drugShipment={drugShipmentForm}
                    modalOpen={drugShipmentReceivedModalOpen}
                    setModalOpen={setDrugShipmentReceivedModalOpen}
                    onFormSave={onDrugShipmentReceivedFormSave}
                    onExit={onDrugShipmentReceivedExit}
                    drugShipmentVerifyActions={drugShipmentVerifyActions}
                />
                <DrugShipmentStatusModal
                    drugShipment={drugShipmentForm}
                    newStatus={newShipmentStatus}
                    modalOpen={drugShipmentStatusModalOpen}
                    setModalOpen={setDrugShipmentStatusModalOpen}
                    onFormSave={onDrugShipmentStatusFormSave}
                    drugShipmentActions={drugShipmentActions}
                />
                {
                    deleteDrugShipmentId ?
                        <DrugShipmentDeleteModal
                            drugShipmentId={deleteDrugShipmentId}
                            modalOpen={drugShipmentDeleteModalOpen}
                            setModalOpen={setDrugShipmentDeleteModalOpen}
                            onFormSave={onDrugShipmentDelete}
                            onFormClose={onDrugShipmentDeleteClose}
                            drugShipmentActions={drugShipmentActions}
                        /> :
                        null
                }
                <Typography
                    variant="h1"
                    color="secondary"
                >
                    Drug Management
                </Typography>
                <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item xs={6}>
                        <InstitutionSelector
                            allowAll={true}
                            onChange={onSelectedInstChange}
                            value={params.institutionCode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {
                            !!canAdministerDrugManagement && (
                                <div
                                    className={classes.buttonSet}
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={addPharmacyClick}
                                        disabled={!params.institutionCode || (!!pharmaciesToUse && pharmaciesToUse.length > 0)}
                                    >
                                        Add Pharmacy
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        component={Link}
                                        to={`${drugManagementContext.baseUrl}/batches`}
                                    >
                                        Manage Batches
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        component={Link}
                                        to={`${drugManagementContext.baseUrl}/depots`}
                                    >
                                        Manage Depots
                                    </Button>
                                </div>
                            )
                        }
                    </Grid>
                </Grid>
                <CollapsibleTable
                    key={`pharmacy-table-${params.institutionCode ?? 'null'}`}
                    title="Pharmacy"
                    loading={pharmaciesLoading}
                    data={convertedPharmacies ?? []}
                    columns={pharmacyColumns}
                    onRowClick={onPharmacyRowClick}
                />
                {
                    !!canAdministerDrugManagement && (
                        <div
                            className={classes.buttonSet}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={addDrugShipmentClick}
                                disabled={!params.institutionCode || !convertedPharmacies || convertedPharmacies.length === 0}
                            >
                                Add Drug Shipment
                            </Button>
                        </div>
                    )
                }
                <CollapsibleTable
                    key={`drug-shipment-table-${params.institutionCode ?? 'null'}`}
                    title="Drug Shipment"
                    loading={drugShipmentsLoading}
                    data={drugShipmentsToUse ?? []}
                    columns={drugShipmentColumns}
                    onRowClick={onDrugShipmentRowClick}
                    entityName="Shipment"
                />
                <CollapsibleTable
                    key={`patients-table-${params.institutionCode ?? 'null'}`}
                    title="Patient"
                    loading={patientsLoading}
                    data={patientsToUse ?? []}
                    columns={patientColumns as any}
                    onRowClick={onPatientRowClick}
                />
            </div>
        </>
    );
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default DrugManagementPage;