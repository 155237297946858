/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the depot management page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { RequestState, IRequestState } from '@ngt/request-utilities';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, MenuItem, makeStyles, Theme } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Field, Form, Select, DatePicker, ResponseStatus, Numeric, SubmitButton, useFormState, useSnackbar } from '@ngt/opms';

import { DateTime } from 'luxon';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { IUseDrugDispensationActions, useDrugDispensation } from '../hooks/useDrugDispensation';
import { selectDistinctObjects } from '../utilities/selectDistinctObjects';
import { getDrugUnitName } from '../utilities/getDrugUnitName';
import { convertStringToDate } from '../utilities/convertStringToDate';
import useModal from '../hooks/useModal';
import { useValidation } from '../hooks/useValidation';
import pluralize from 'pluralize';
import { capitalise } from '../utilities/capitalise';
import { AlertTitle } from '@material-ui/lab';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogContent: {
        padding: '0'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugDispensationModalProps {
    drugDispensation: Dtos.DrugDispensation | null;
    timepoints: Dtos.Timepoint[] | null;
    timepointsLoadState: IRequestState<ResponseStatus>;
    drugShipmentDepotBatches: Dtos.DrugShipmentDepotBatch[] | null;
    drugShipmentDepotBatchesLoadState: IRequestState<ResponseStatus>;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    drugDispensationActions: IUseDrugDispensationActions;
}

interface IDrugDispensationFormProps {
    drugDispensation: Dtos.DrugDispensation | null;
    drugDispensationActions: IUseDrugDispensationActions;
    isEdit: boolean;
    timepoints: Dtos.Timepoint[] | null;
    timepointsLoadState: IRequestState<ResponseStatus>;
    drugShipmentDepotBatches: Dtos.DrugShipmentDepotBatch[] | null;
    drugShipmentDepotBatchesLoadState: IRequestState<ResponseStatus>;
    setModalOpen: (open: boolean) => void;
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const DrugDispensationForm: React.FunctionComponent<IDrugDispensationFormProps> = ({
    isEdit,
    timepoints,
    timepointsLoadState,
    drugShipmentDepotBatches,
    drugShipmentDepotBatchesLoadState,
    setModalOpen
}) => {

    const drugUnitName = getDrugUnitName(true);

    const classes = useStyles();

    const onCloseClick = React.useCallback(() => {
        setModalOpen(false);
    }, [setModalOpen]);

    const drugShipmentDepotBatchItems = React.useMemo(() => {
        if (!drugShipmentDepotBatches || drugShipmentDepotBatches.length === 0) {
            return [];
        }
        else {
            const shipmentBatches = isEdit ?
                drugShipmentDepotBatches.filter(dsb => dsb.drugShipment.shipmentStatus === Dtos.ShipmentStatus.Available) :
                drugShipmentDepotBatches.filter(dsb => dsb.drugShipment.shipmentStatus === Dtos.ShipmentStatus.Available &&
                    ((dsb.drugUnitsUsed!! +
                        dsb.drugUnitsDamaged!! +
                        dsb.drugUnitsDestroyed!! +
                        dsb.drugUnitsLost!! +
                        dsb.drugUnitsQuarantined!!) < dsb.drugUnits!!));

            const distinctDepotBatches = selectDistinctObjects(shipmentBatches.map(dsdb => dsdb.depotBatch),
                (reduceObject, returnObject) => reduceObject.id === returnObject.id)
                .sort((a: Dtos.DepotBatch, b: Dtos.DepotBatch) => {
                    if (a.batch.drugId!! > b.batch.drugId!!) {
                        return 1;
                    }
                    else if (a.batch.drugId!! < b.batch.drugId!!) {
                        return -1;
                    }
                    else if (a.batch.dateExpiration!! > b.batch.dateExpiration!!) {
                        return 1;
                    }
                    else if (a.batch.dateExpiration!! < b.batch.dateExpiration!!) {
                        return -1;
                    }
                    else {
                        return a.id!! - b.id!!;
                    }
                });

            return distinctDepotBatches.map(depotBatch => {
                return <MenuItem
                    key={depotBatch.id}
                    value={depotBatch.id}
                >
                    {`${depotBatch.batch.drug.drugName} (${depotBatch.batch.batchNo} - ${depotBatch.depotBatchNo} Expires ${DateTime.fromISO(depotBatch.batch.dateExpiration!!).toFormat('dd/MM/yyyy')})`}
                </MenuItem>;
            });
        }
    }, [drugShipmentDepotBatches, isEdit]);

    const timepointItems = React.useMemo(() => {
        if (!timepoints || timepoints.length === 0) {
            return [];
        }
        else {
            return timepoints.map(timepoint => {
                return <MenuItem
                    key={timepoint.id}
                    value={timepoint.id}
                >
                    {timepoint.value}
                </MenuItem>;
            });
        }
    }, [timepoints]);

    const { values: formValues } = useFormState<Dtos.DrugDispensation, any>({ values: true });

    const unitLabel = React.useMemo(() => {
        if (!formValues ||
            !formValues.depotBatchId ||
            !drugShipmentDepotBatches ||
            drugShipmentDepotBatches.length === 0) {
            return drugUnitName;
        }

        const label = drugShipmentDepotBatches.find(dsdb => dsdb.depotBatchId === formValues.depotBatchId &&
            !!dsdb.depotBatch?.batch?.drug?.containerName)?.depotBatch.batch.drug.containerName;

        if (!label) {
            return drugUnitName;
        }

        return capitalise(pluralize.plural(label));
    }, [formValues, drugUnitName, drugShipmentDepotBatches]);

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                {
                    timepointsLoadState && timepointsLoadState.state && timepointsLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="timepointId"
                            component={Select}
                            label="Timepoint"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {timepointItems}
                        </Field>
                }
                <Field
                    name="dateDispensation"
                    component={DatePicker}
                    label="Date"
                    parse={convertStringToDate}
                />
                {
                    drugShipmentDepotBatchesLoadState && drugShipmentDepotBatchesLoadState.state && drugShipmentDepotBatchesLoadState.state === RequestState.Pending ?
                        <CircularProgress /> :
                        <Field
                            name="depotBatchId"
                            component={Select}
                            label="Batch"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                        >
                            {drugShipmentDepotBatchItems}
                        </Field>
                }
                <Field
                    name="drugUnits"
                    component={Numeric}
                    label={`${unitLabel}`}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const DrugDispensationModal: React.FunctionComponent<IDrugDispensationModalProps> = ({
    drugDispensation,
    timepoints,
    timepointsLoadState,
    drugShipmentDepotBatches,
    drugShipmentDepotBatchesLoadState,
    modalOpen,
    setModalOpen,
    onCloseClick,
    onFormSave,
    drugDispensationActions
}) => {
    const isEdit = !!drugDispensation && !!drugDispensation.id;
    
    const onValidate = useValidation<Dtos.DrugDispensation>((formValues) => new Dtos.ValidateDrugDispensation({ drugDispensation: formValues }));

    const { enqueueSnackbar } = useSnackbar();

    const [, , drugDispensationSaveState, ,] = useDrugDispensation();

    const onDrugDispensationFormSaveError = React.useCallback(() => {

    }, []);

    React.useEffect(() => {
        drugDispensationSaveState.responseStatus?.errorCode === 'ClientViewableException' && enqueueSnackbar(
            <>
                <AlertTitle>
                    {
                        `Drug Dispensation Not Saved`
                    }
                </AlertTitle>
                {
                    drugDispensationSaveState.responseStatus?.errorCode === 'ClientViewableException' ?
                        drugDispensationSaveState.responseStatus?.message : `An error occurred while attempting to save the drug dispensation.`
                }
            </>,
            { variant: 'critical' }
        );
    }, [drugDispensationSaveState.responseStatus?.errorCode]);

    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'drug dispensation',
        asyncSave: drugDispensationActions.asyncSave,
        onFormSave: onFormSave,
        onValidate: onValidate,
        onFormSaveError: onDrugDispensationFormSaveError
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Change Drug Dispensation
                </DialogTitle>
                <Form
                    initialValues={drugDispensation}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <DrugDispensationForm
                        isEdit={isEdit}
                        timepoints={timepoints}
                        timepointsLoadState={timepointsLoadState}
                        drugShipmentDepotBatches={drugShipmentDepotBatches}
                        drugShipmentDepotBatchesLoadState={drugShipmentDepotBatchesLoadState}
                        setModalOpen={setModalOpen} 
                        drugDispensation={drugDispensation} 
                        drugDispensationActions={drugDispensationActions}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default DrugDispensationModal;