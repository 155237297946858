
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains a pharmacist checklist component.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

import React from "react";
import { grey } from "@material-ui/core/colors";
import {
    Checkbox as MuiCheckbox,
    List as MuiList,
    ListItemSecondaryAction as MuiListItemSecondaryAction,
    ListItemText as MuiListItemText,
    ListProps as MuiListProps,
    MenuItem as MuiMenuItem
} from "@material-ui/core"

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import { IInputRenderProps, IValidationError } from "@ngt/opms";

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

type PharmacistCheckListProps = Partial<Omit<MuiListProps, 'type'>> & IInputRenderProps<any[], IValidationError>;

export interface IPharmacistCheckListProps extends PharmacistCheckListProps {
    options: any[] | null;
    optionsTextFormat: (item: any) => string;
    optionsStoreFormat: (item: any) => any;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */


/*
 * ---------------------------------------------------------------------------------
 * components
 * ---------------------------------------------------------------------------------
 */

const PharmacistCheckList: React.FunctionComponent<IPharmacistCheckListProps> = ({
    inputRender: { state: { value }, actions: { update: onInputChange, blur: onBlur, focus: onFocus } },
    options,
    optionsTextFormat,
    optionsStoreFormat,
    ...rest
}) => {
    const onClickCombined = React.useCallback((selected) => {
        const match = value?.find(x => x.pharmacistId === selected.id);

        const valueCopy = value ?? [];

        if (!match) {
            const toObj = optionsStoreFormat(selected);
            valueCopy.push(toObj);
        } else {
            valueCopy.splice(value?.indexOf(match)!, 1);
        }

        onInputChange([...valueCopy]);
    }, [value, onInputChange]);

    const onFocusCombined = React.useCallback(() => {
        onFocus();
    }, [onFocus]);

    const onBlurCombined = React.useCallback(() => {
        onBlur();
    }, [onBlur]);

    return (
        <MuiList
            {...rest as any}
            inputProps={{
                onBlur: onBlurCombined,
                onFocus: onFocusCombined
            }}
        >
            {
                options &&
                options.length > 0 &&
                options.map((option, i) => (
                    <MuiMenuItem
                        key={option.id}
                        value={option.id}
                        style={{
                            borderBottom: `${i !== options.length - 1 ? (`1px solid ${grey[300]}`) : 'none'}`
                        }}
                        onClick={() => onClickCombined(option)}
                    >
                        <MuiListItemText
                            primary={optionsTextFormat(option)}
                        />
                        <MuiListItemSecondaryAction>
                            <MuiCheckbox
                                disableRipple
                                checked={!!value?.find(x => x.pharmacistId === option.id)}
                                onClick={() => onClickCombined(option)}
                            />
                        </MuiListItemSecondaryAction>
                    </MuiMenuItem>
                ))
            }
        </MuiList>
    );
}

export default PharmacistCheckList;