/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the drugShipmentDepotBatch management page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, DialogTitle, DialogContent, Dialog, DialogActions, Grid, Theme, makeStyles } from '@material-ui/core';

import * as Yup from 'yup';

import { Field, Form, Text, Numeric, SubmitButton } from '@ngt/opms';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../api/dtos';
import { getDrugUnitName } from '../utilities/getDrugUnitName';
import useModal from '../hooks/useModal';
import { IUseDrugShipmentDepotBatchActions } from '../hooks/useDrugShipmentDepotBatch';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    dialogContent: {
        padding: '0'
    }
}));



/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IDrugShipmentDepotBatchModalProps {
    drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch | null;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onCloseClick?: () => void;
    onFormSave?: () => void;
    drugShipmentDepotBatchActions: IUseDrugShipmentDepotBatchActions;
}

interface IDrugShipmentDepotBatchFormProps {
    drugShipmentDepotBatch: Dtos.DrugShipmentDepotBatch | null;
    setModalOpen: (open: boolean) => void;
}

interface IDrugShipmentDepotBatchForm extends Partial<Dtos.DrugShipmentDepotBatch> {
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const validation = Yup.object().shape<IDrugShipmentDepotBatchForm>({
    drugUnits: Yup.number().required()
        .when(['drugUnitsUsed', 'drugUnitsLost', 'drugUnitsDamaged', 'drugUnitsDestroyed', 'drugUnitsQuarantined'],
            (drugUnitsUsed: number, drugUnitsLost: number, drugUnitsDamaged: number, drugUnitsDestroyed: number, drugUnitsQuarantined: number, schema: Yup.NumberSchema) => {
                return schema.min(drugUnitsUsed + drugUnitsLost + drugUnitsDamaged + drugUnitsDestroyed + drugUnitsQuarantined, `Total can not be less than the sum of the rest of the drug unit values`);
            }),
    drugUnitsUsed: Yup.number().required().min(0),
    drugUnitsLost: Yup.number().required().min(0),
    drugUnitsDamaged: Yup.number().required().min(0),
    drugUnitsDestroyed: Yup.number().required().min(0),
    drugUnitsQuarantined: Yup.number().required().min(0)
});

const DrugShipmentDepotBatchForm: React.FunctionComponent<IDrugShipmentDepotBatchFormProps> = ({
    setModalOpen
}) => {
    const classes = useStyles();

    const onCloseClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setModalOpen(false);
    }, [setModalOpen]);

    const drugUnitName = getDrugUnitName(true);

    return (
        <>
            <DialogContent
                className={classes.dialogContent}
            >
                <Field
                    name="drugUnits"
                    component={Numeric}
                    label={`Total ${drugUnitName}`}
                    disabled={true}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="drugUnitsUsed"
                    component={Numeric}
                    label={`${drugUnitName} Used`}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="drugUnitsDamaged"
                    component={Numeric}
                    label={`${drugUnitName} Damaged`}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="drugUnitsDestroyed"
                    component={Numeric}
                    label={`${drugUnitName} Destroyed`}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="drugUnitsLost"
                    component={Numeric}
                    label={`${drugUnitName} Lost`}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="drugUnitsQuarantined"
                    component={Numeric}
                    label={`${drugUnitName} Quarantined`}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
                <Field
                    name="notes"
                    component={Text}
                    label="Notes"
                    multiline={true}
                    rows={4}
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCloseClick} color="primary">
                    Cancel
                </Button>
                <SubmitButton variant="contained" color="primary">
                    OK
                </SubmitButton>
            </DialogActions>
        </>
    );
};

const DrugShipmentDepotBatchModal: React.FunctionComponent<IDrugShipmentDepotBatchModalProps> = ({
    drugShipmentDepotBatch,
    modalOpen,
    setModalOpen,
    onCloseClick,
    onFormSave,
    drugShipmentDepotBatchActions
}) => {
    const {
        handleSubmit,
        debouncedValidate,
        onFormSubmitFailure
    } = useModal({
        entityName: 'Drug Shipment',
        validation: validation,
        asyncSave: drugShipmentDepotBatchActions.asyncSave,
        onFormSave: onFormSave
    });

    return (
        <>
            <Dialog
                open={modalOpen}
                onClose={onCloseClick}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md"
                scroll="body"
            >
                <DialogTitle id="alert-dialog-title">
                    Save Drug Shipment
                </DialogTitle>
                <Form
                    initialValues={drugShipmentDepotBatch}
                    validateOn="onChange"
                    onSubmit={handleSubmit}
                    onValidate={debouncedValidate}
                    onSubmitFailed={onFormSubmitFailure}
                >
                    <DrugShipmentDepotBatchForm
                        drugShipmentDepotBatch={drugShipmentDepotBatch}
                        setModalOpen={setModalOpen}
                    />
                </Form>
            </Dialog>
        </>
    );
}

export default DrugShipmentDepotBatchModal;